import { useEffect, useState } from 'react';
import './App.css';
import logo from './logo.png';

const config = {
  debug: true,
  // freeze: true,
  showFoodInMiniCams: false,

  screenW: window.innerWidth,
  screenH: window.innerHeight,
  hasTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0,

  baseSpeed: 50,
  initPlayerSize: 6,
  foodSize: 2,
  joystickFullGasDistance: 50,
  joystickNoGasDistance: 10,

  eatingSizeGainRatio: 0.4,

  defaultZoom: 5,
  zoomOutRatio: 0.6,

  lsKey: 'karagiri',
}

const id = () => Math.random().toString(36).substring(2, 15)
const color = () => `hsl(${random(0, 360)}, ${random(90, 100)}%, ${random(40, 60)}%)`
const random = (min, max) => Math.random() * (max - min) + min
const degToRad = deg => (deg - 90) * Math.PI / 180
const distance = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))

// const speedOf = size => config.baseSpeed * 100 * Math.pow(size + 90, -1)
// const speedOf = size => config.baseSpeed * 50 * Math.pow(size + 40, -1)
const speedOf = size => config.baseSpeed * 40 * Math.pow(size + 30, -1)
const canEatFood = (player, food) => distance(player, food) < player.size / 2
const canEatPlayer = (player, otherPlayer) => player.size > otherPlayer.size * 1.1 &&
  distance(player, otherPlayer) < player.size / 2 - (otherPlayer.size / 2) * 0.7
const spawn = (obj) => {
  // a random point in the world where there is no player
  obj.x = random(game.world.l, game.world.r)
  obj.y = random(game.world.t, game.world.b)
  return obj
}
const spawnFood = () => foods[id()] = spawn({})
const spawnCurrentPlayer = () => {
  Object.assign(currentPlayer, {color: color(), angel: 0, gas: 0, size: config.initPlayerSize})
  players[game.pid] = spawn(currentPlayer)
}

const game = {
  playing: false,
  over: false,
  world: { t: 4_700, l: 4_700, r: 5_300, b: 5_300, w: 600, h: 600 },
  pid: id(),
  player: {
    name: '',
    lastSize: 0,
    biggestSize: 0,
    ...JSON.parse(localStorage.getItem(config.lsKey) || '{}')
  }
}
// const currentPlayer = { name: game.player.name, color: game.player.color, angel: 0, gas: 0, size: config.initPlayerSize, x: 5_000, y: 5_000 }
const currentPlayer = { name: game.player.name }
const players = {
  // [game.pid]: currentPlayer,
  [id()]: { name: "y", color: color(), x: 5_050, y: 5_020, angel: degToRad(-90), gas: 0, size: 20 },
  [id()]: { name: "z", color: color(), x: 4_990, y: 4_980, angel: degToRad(180), gas: 0, size: 15 },
  [id()]: { name: "s", color: color(), x: 5_290, y: 4_980, angel: degToRad(90), gas: 0, size: 100 },
}
const foods = {}
' '.repeat(500).split('').forEach(spawnFood)
// spawnCurrentPlayer()

const joystick = { angel: 0, gas: 1 }
function moveJoystick({cx, cy, x, y}) {
  const dX = x - cx
  const dY = y - cy
  joystick.angel = Math.atan2(dY, dX)
  const distance = Math.sqrt(dX * dX + dY * dY)
  if (distance < config.joystickNoGasDistance) {
    joystick.gas = 0
  } else {
    joystick.gas = Math.min(1, distance / config.joystickFullGasDistance)
  }
}

document.addEventListener("mousemove", (e) => {
  moveJoystick({
    cx: config.screenW / 2,
    cy: config.screenH / 2,
    x: e.clientX,
    y: e.clientY,
  })
})

window.addEventListener('resize', () => {
  config.screenW = window.innerWidth
  config.screenH = window.innerHeight
})

document.addEventListener("click", (e) => {
  // currentPlayer.size += 10
});

function touchHandler (e) {
  e.preventDefault()
  moveJoystick({
    cx: config.screenW / 2,
    cy: config.screenH / 2,
    x: e.touches[0].clientX,
    y: e.touches[0].clientY,
  })
}
document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('touchstart', touchHandler, false)
  document.addEventListener('touchmove', touchHandler, false)
  document.addEventListener('touchend', e => {
    joystick.gas = 0
  }, false)
})

const animation = { _initiated: false, lastFrame: 0, lastMoment: 0, frameTime: 0, fps: 0 };
function useAnimationFrameRenderer() {
	const [, setRenderFrame] = useState(0);
	useEffect(() => {
    const animate = () => {
      const now = performance.now()
      if (animation.lastMoment) {
        animation.frameTime = (now - animation.lastMoment) / 1000;
        animation.fps = Math.round(1 / animation.frameTime)
      }
      animation.lastMoment = now
      frameHandler()
      animation.lastFrame = requestAnimationFrame(animate)
      setRenderFrame(animation.lastFrame)
    }
    if (!animation._initiated) {
      animation._initiated = true
      animate()
    }
	}, [])
}

function frameHandler() {
  if (config.freeze) return
  if (!game.playing) return

  // current player's movement decision
  currentPlayer.angel = joystick.angel
  currentPlayer.gas = joystick.gas

  // all player displacements
  for (let pid in players) {
    const player = players[pid]
    player._speed = speedOf(player.size) * player.gas

    const displacement = player._speed * animation.frameTime
    player.x += Math.cos(player.angel) * displacement
    player.y += Math.sin(player.angel) * displacement

    if (player.x < game.world.l) player.x = game.world.l
    if (player.x > game.world.r) player.x = game.world.r
    if (player.y < game.world.t) player.y = game.world.t
    if (player.y > game.world.b) player.y = game.world.b

    // detect current player's collision with other players
    if (player !== currentPlayer && player._isInView && canEatPlayer(currentPlayer, player)) {
      currentPlayer.size += player.size * config.eatingSizeGainRatio
      delete players[pid]
      broadcast('playerEaten', { player: game.pid, ate: pid, size: currentPlayer.size })
    }
  }

  // detect current player's collision with food
  for (let fid in foods) {
    const food = foods[fid]
    if (food._isInView && canEatFood(currentPlayer, food)) {
      delete foods[fid]
      spawnFood()
      currentPlayer.size += 1 * config.eatingSizeGainRatio
      broadcast('foodEaten', { player: game.pid, ate: fid, size: currentPlayer.size })
    }
  }
}

function broadcast(event, data) {
}

function App() {
  useAnimationFrameRenderer();
  const [renderAnimation, setRenderAnimation] = useState(0)
  const changeName = e => {
    game.player.name = e.target.value.trim()
    localStorage.setItem(config.lsKey, JSON.stringify(game.player))
  }
  const start = (e) => {
    e.preventDefault()
    if (!game.player.name) {
      setRenderAnimation(n => n + 1)
    } else {
      spawnCurrentPlayer()
      game.playing = true
    }
  }
  return <>
    { game.playing ? <Game /> : <div className='login'>
      <div className='logo'>
        <img src={logo} alt='Karagiri' />
      </div>
      <form className='form' onSubmit={start}>
        <input type='text' placeholder='نام' value={game.player.name} onChange={changeName} key={renderAnimation} className={renderAnimation ? 'shake' : ''} />
        <button onClick={start}>شروع</button>
      </form>
    </div> }
  </>
}

function Game() {
  const mainCamZoom = Math.min(config.defaultZoom, config.zoomOutRatio * Math.min(config.screenH, config.screenW) / currentPlayer.size)
  
  return <>
    {/* Main Worldview Cam */}
    <Locate x={0} y={0} w={config.screenW} h={config.screenH}>
      <Camera cx={currentPlayer.x} cy={currentPlayer.y} w={config.screenW} h={config.screenH} zoom={mainCamZoom} mainCam />
    </Locate>

    { config.debug && <>
      {/* Stats */}
      <Locate x={10} y={10}>
        Foods: {Object.keys(foods).length} <br />
        X: {currentPlayer.x} Y: {currentPlayer.y} <br />
        Size: {currentPlayer.size}, Speed: {currentPlayer._speed} <br />
        Joystick Angel: {joystick.angel}, Gas: {Math.round(joystick.gas * 100)}% <br />
        FPS: {animation.fps}
      </Locate>

      {/* Mini MAP */}
      <Locate x={10} b={120} w={100} h={100} box>
        <Camera cx={5_000} cy={5_000} w={100} h={100} zoom={-8} />
      </Locate>

      {/* Mini CAM */}
      <Locate x={10} b={10} w={100} h={100} box>
        <Camera cx={currentPlayer.x} cy={currentPlayer.y} w={100} h={100} zoom={-8} />
      </Locate>

      {/* Joystick debug arrow (from center to distance) */}
      <Locate x={config.screenW / 2} y={config.screenH / 2}>
        <DebugAngel size={joystick.gas * config.joystickFullGasDistance * 2} angel={joystick.angel} located />
      </Locate>
    </> }
  </>
}

function Camera({ cx, cy, w, h, zoom = 1, mainCam }) {
  const scale = zoom < 0 ? 1 / -zoom : zoom
  const inViewW = w / scale
  const inViewH = h / scale
  const inViewLeft = cx - inViewW / 2
  const inViewRight = cx + inViewW / 2
  const inViewTop = cy - inViewH / 2
  const inViewBottom = cy + inViewH / 2
  const isObjectInView = ({ x, y, size}) => {
    if (x + size / 2 < inViewLeft) return false
    if (x - size / 2 > inViewRight) return false
    if (y + size / 2 < inViewTop) return false
    if (y - size / 2 > inViewBottom) return false
    return true;
  }
  const inViewSize = size => size * scale
  const inViewX = x => (x - cx) * scale + w / 2
  const inViewY = y => (y - cy) * scale + h / 2

  const inViewObjects = []

  for (let pid in players) {
    const player = players[pid]
    const isInView = isObjectInView(player)
    if (mainCam) {
      player._isInView = isInView
    }
    if (isInView) {
      const size = inViewSize(player.size)
      inViewObjects.push(<Player {...player} size={size} key={pid} cx={inViewX(player.x)} cy={inViewY(player.y)} />)
    }
  }

  if(mainCam || config.showFoodInMiniCams) {
    for (let fid in foods) {
      const f = foods[fid]
      const size = inViewSize(config.foodSize)
      const isInView = isObjectInView({...f, size: config.foodSize})
      if (mainCam) {
        f._isInView = isInView
      }
      if (isInView) {
        inViewObjects.push(<Locate key={fid} w={size} h={size} cx={inViewX(f.x)} cy={inViewY(f.y)}>
          <Food {...f} size={size} />
        </Locate>)
      }
    }
  }

  return <div style={{ backgroundColor: 'rgb(233 191 158)', width: w, height: h }}>{inViewObjects}</div>
}

function Player({ name, color, size, angel, cx, cy }) {
  const eye = size * 0.1
  return <div className='player' style={{
      width: size,
      height: size,
      backgroundColor: color,
      zIndex: parseInt(size * 200) - config.initPlayerSize * 200 + 1,
      top: cy,
      left: cx,
    }}>
      <p>{name}</p>
    {/* <DebugAngel size={size} angel={angel} /> */}
    {/* Left Eye */}
    {/* <Locate x={size / 4} y={size / 4} w={eye} h={eye}>
      <div style={{ backgroundColor: 'black', borderRadius: '50%', width: eye, height: eye }} />
    </Locate> */}
    {/* Right Eye */}
    {/* <Locate x={size / 4} y={size / 4 * 3} w={eye} h={eye}>
      <div style={{ backgroundColor: 'black', borderRadius: '50%', width: eye, height: eye }} />
    </Locate> */}
  </div>
}

function DebugAngel ({ size, angel, located }) {
  if (!config.debug) return null
  const half = size / 2
  return <div style={{
    width: half,
    height: 1,
    transform: located ? `rotate(${angel}rad)` : `translate(${half}px, ${half}px) rotate(${angel}rad)`,
    transformOrigin: 'top left',
    backgroundColor: 'black',
  }} />
}

function Food({ size }) {
  return <div className='food' style={{ width: size, height: size }} />
}

function Locate({ x, y, r, b, cx, cy, w, h, z, box, children }) {
  if (cx !== undefined) x = cx - w / 2
  if (cy !== undefined) y = cy - h / 2
  return <div style={{
    position: "absolute",
    ...w!==undefined && {width: w},
    ...h!==undefined && {height: h},
    ...x!==undefined && {left: x},
    ...y!==undefined && {top: y},
    ...r!==undefined && {right: r},
    ...b!==undefined && {bottom: b},
    ...z!==undefined && {zIndex: z},
    ...box && {border: "1px solid black"},
    // transition: 'all 0.3s'
  }}>
    {children}
  </div>
}

export default App;
